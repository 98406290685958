import { Component, OnInit } from '@angular/core';
import { NavController, NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-animates',
  templateUrl: './animates.page.html',
  styleUrls: ['./animates.page.scss'],
})
export class AnimatesPage implements OnInit {
  public animates:any = [
    {
      class: "flash"
    },
    {
      class: "pulse"
    },
    {
      class: "rubberBand"
    },
    {
      class: "shake"
    },
    {
      class: "swing"
    },
    {
      class: "tada"
    },
    {
      class: "wobble"
    },
    {
      class: "jello"
    },
    {
      class: "heartBeat"
    },
    {
      class: "zoomIn"
    },
  ];
  public selected:any = {};
  public pricecolor:any = "red";
  public price:any;
  public colors: any = ["red","indigo","hotpink","orange","navy","grey","green","brown","blue"]
  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    let selected = this.navParams.get('animation');
    this.pricecolor = this.navParams.get('pricecolor');
    this.price = this.navParams.get('price');
    if (selected) {
      this.selected = {
        class: selected
      }
    }
  }

  dismiss() {
    this.modalCtrl.dismiss(
      {
        animation: this.selected.class,
        pricecolor: this.pricecolor
      }
    )
  }

  back() {
    this.modalCtrl.dismiss();
  }
  setColor(color) {
    console.log('Color:', color);
    this.pricecolor = color;
  }
  
  colorTouchStart() {

  }
  colorTouchEnd() {

  }

}
