import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';


@Injectable({
  providedIn: 'root'
})
export class HelperService {
  openedFromOut: boolean = false;
  constructor(public http: HttpClient) {
  }

  geturl(route) {
  	let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
  return this.http.get("https://testing.eyadroid.com/app/basic/web/index.php?r=site/" + route, {headers: headers});
}
posturl(route, par) {
      let headers = new HttpHeaders();
      let params = new FormData();
      let vars=  par.split("&");
      for (let i=0;i<vars.length;i++) {
        params.append(vars[i].split("=")[0], vars[i].split("=")[1]);
      }

      console.log("params:", params.toString());
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
  return this.http.post("https://testing.eyadroid.com/app/basic/web/index.php?r=site/" + route, params ,{headers: headers});

}
}
