import { Component, ElementRef, OnInit, ViewChild  } from '@angular/core';
import { Platform, AlertController, LoadingController, ModalController, ActionSheetController, ToastController, NavController, IonContent } from '@ionic/angular';
import { Storage  } from '@ionic/storage';

import { ImagePicker } from '@ionic-native/image-picker/ngx';

import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
import * as $ from 'jquery'
import { DomSanitizer } from '@angular/platform-browser';
import { HelperService } from '../helper.service';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { CitysPage } from '../citys/citys.page';
import { AnimatesPage } from '../animates/animates.page';
import { TermsPage } from '../terms/terms.page';
import { IosVersion } from '../utils/IosVersion';

@Component({
  selector: 'app-add-last',
  templateUrl: './add-last.page.html',
  styleUrls: ['./add-last.page.scss'],
})
export class AddLastPage implements OnInit {
  @ViewChild('content', null) content:IonContent;
  nativepath: any;
  ismale: boolean = true;
  isall: boolean = false;
   isfemale: boolean = false;
   malec: string = 'transparent';
   femalec: string = 'transparent';
   allc: string = 'transparent';
  
    maleco: string = 'black';
   femaleco: string = 'black';
   allco: string = 'black';
  which: string;
  images: any = [];
  imgpath: any;
  isApp:boolean;
  showfoot: boolean = true;

  public imgblob: any = 'assets/images/camera-icon-.png';
  lat: any;
  lng: any;
  arl : boolean = false;
  city: any;
  locationInterval: any;
  category:any;
  number: any;
  wnumber: any;
  private isOpenedWhatsappModel:boolean = false;
  code:any;
  fg:any;
  public timer:number = 0;
  public description:string = "";
  imagesName: any = [];
  location: any;
   myname:any;
   title: any;
   price: any;
   pricec: any;
   time: any;
   yvideo: any;
   loginid: any;
   logintype: any;
   pricebefore: any;
   videoblob: any;
   website: any;
   websitebefore: any;
   isyoutube: any = 1;
   public animation:string;
   public pricecolor:string = "#FF0000";
  private pInterval:any;
  private pTimeOut:any;
  private mInterval:any;
  private mTimeOut:any;
  private perviouseWebsiteValue;
  tags:string;
  constructor(
     private imagePicker: ImagePicker,
    public alertCtrl: AlertController,
    private camera: Camera, public  loadingCtrl: LoadingController,
    private el:ElementRef, public modalCtrl: ModalController, public translate: TranslateService,
    public platform: Platform,
    public actionSheetCtrl: ActionSheetController, public toastCtrl: ToastController,
    private sanitizer: DomSanitizer, private storage: Storage, private helper: HelperService,
    private oneSignal: OneSignal,
    private navbar: NavController, private iosVersion:IosVersion
  ) { }

  async ngOnInit() {
    this.isApp = this.platform.is('cordova');
  	    if (this.translate.store.defaultLang == 'ar') {
      this.arl = true;
    }
  }


  toggleg(val) {
    this.category = val;
  if (val == "male") {
    this.isall = false;
    this.isfemale = false;
    this.ismale = true;
    this.allc = 'transparent';
    this.femalec = 'transparent';
    this.malec = '#3b5998';

    this.allco = 'black';
    this.femaleco = 'black';
    this.maleco = '#fff';
  } 
  else if (val == "all") {
    this.ismale = false;
    this.isfemale = false;
    this.isall = true;
    this.malec = 'transparent';
    this.femalec = 'transparent';
    this.allc = '#3b5998';

    this.maleco = 'black';
    this.femaleco = 'black';
    this.allco = '#fff';
  }
  else {
    this.isall = false;
    this.ismale = false;
    this.isfemale = true;
    this.allc = 'transparent';
    this.malec = 'transparent';
    this.femalec = '#3b5998';

    this.allco = 'black';
    this.maleco = 'black';
    this.femaleco = '#fff';
  }
}

timerp() {
  if (this.timer < 720) {
  this.timer++;
} else {
  this.timer = 1;
}
}
timerm() {
  if (this.timer > 1) {
  this.timer--;
} else {
  this.timer = 720;
}
}

buttonFocus() {
  console.log('focus');
  this.pTimeOut = setTimeout(()=>{ 
    this.pInterval = setInterval(this.timerp.bind(this), 200);
  }, 1000)
}

buttonBlur() {
  console.log('blur');
  if (this.pInterval) clearInterval(this.pInterval);
  if (this.pTimeOut) clearInterval(this.pTimeOut);
}

mbuttonFocus() {
  console.log('focus');
  this.mTimeOut = setTimeout(()=>{ 
    this.mInterval = setInterval(this.timerm.bind(this), 200);
  }, 1000)
}

mbuttonBlur() {
  console.log('blur');
  if (this.mInterval) clearInterval(this.mInterval);
  if (this.mTimeOut) clearInterval(this.mTimeOut);
}

contentClicked() {
  this.mbuttonBlur();
  this.buttonBlur();
}

  async selectCity() {
    let mo = await this.modalCtrl.create({
      component: CitysPage
    });
    mo.present();
    mo.onDidDismiss().then((data:any) => {
    console.log(data);
    data = data.data;
    if(data!=null){
      this.city = data.city;
    }
    })
  }

  public async selectAnimation() {
  	
    if (this.price == null || this.price == ' ' || !this.price) {
      const alert = await this.alertCtrl.create({
                header: '',
                message: this.translate.getParsedResult('en', 'Please type a price'),
                buttons: [{
                    text: this.translate.getParsedResult('en', 'Ok'),
                    role: 'cancel',
                    handler: () => {
                        alert.dismiss();
                    }
                }]
            });
            alert.present();
    }
    else {
      let mo = await this.modalCtrl.create({
        component: AnimatesPage,
        componentProps: {
          animation: this.animation,
          price: this.price,
          pricecolor: this.pricecolor
        }
      });
      mo.present();
      mo.onDidDismiss().then((data:any)=> {
        data = data.data;
        this.animation = data.animation;
        this.pricecolor = data.pricecolor;
      })
    }
  }

  async uploadimg(which) {
    if (this.platform.is('ios') && this.iosVersion.get() >= 14.2 ) {
      this.uploadfn(which);
    }
    else {
      this.which = which;

      const actionSheet = await this.actionSheetCtrl.create({
        buttons: [
          {
            text: this.translate.getParsedResult('en','Take a picture'),
            icon: 'camera',
            handler: () => {
              this.TakePhoto(which);
            }
          },{
            text: this.translate.getParsedResult('en','Select from studio'),
            icon: 'images',
            handler: () => {
              this.uploadfn(which);
            }
          },
          {
            text: this.translate.getParsedResult('en',"Select a Gif"),
            icon: "ios-image",
            handler: ()=> {
                this.selectGif(which);
            }
          },{
            text: this.translate.getParsedResult('en','Cancel'),
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          }
        ]
      });
      actionSheet.present();
  }
  }
  onFileChange(event) {
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = async () => {
        if (event.target.files[0].size > 5000000) {
        (await this.alertCtrl.create({
            header: '',
            message: this.translate.getParsedResult('en', 'Image size is too big'),
            buttons: [{
                text: this.translate.getParsedResult('en', 'Ok'),
                role: 'cancel'
            }]
        })).present();
      }
      else
        if (file.type == "image/gif") {
          console.log(file.type);
          if (this.which == 'main') {
            // let x = (data) => this.imgblob = data;
            this.imgblob = await reader.result.toString();
            this.imgpath = await  reader.result.toString().replace("data:image/gif;base64,", "");
            
          }
          else {
            await this.images.push(
                {
                  src: reader.result.toString(),
                  preview: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
                }
              )
  
          }
        }
      };
    }
  }

  onSelectPictureChange(event) {
    console.log("CLick triggred");
    let reader = new FileReader();
  if(event.target.files && event.target.files.length > 0) {
    let file = event.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = async () => {
      if (file.type == "image/png" || file.type == "image/x-png" || file.type == "image/jpeg") {
        console.log(file.type);
        if (this.which == 'main') {
          // let x = (data) => this.imgblob = data;
          this.imgblob = await reader.result.toString();
          this.imgpath = await reader.result.toString().replace(/^data:image\/[a-z]+;base64,/, "");
        }
        else {
          await this.images.push(
              {
                src: reader.result.toString(),
                preview: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
              }
            )

        }
      }
    };
  }
  }
  
selectGif(which) {
  //     var input:any = $(document.createElement("input"));
  //       input.attr("type", "file");
  //       input.attr("multiple", false);
  //       // add onchange handler if you wish to get the file :)
  //       let trigger = (event)=> {
  //         console.log("CLick triggred");
  //   let reader = new FileReader();
  // if(event.target.files && event.target.files.length > 0) {
  //   let file = event.target.files[0];
  //   reader.readAsDataURL(file);
  //   reader.onload = async () => {
  //     if (event.target.files[0].size > 5000000) {
  //       (await this.alertCtrl.create({
  //           header: '',
  //           message: this.translate.getParsedResult('en', 'Image size is too big'),
  //           buttons: [{
  //               text: this.translate.getParsedResult('en', 'Ok'),
  //               role: 'cancel'
  //           }]
  //       })).present();
  //     }
  //     else
  //     if (file.type == "image/gif") {
  //       console.log(file.type);
  //       if (this.which == 'main') {
  //         // let x = (data) => this.imgblob = data;
  //         this.imgblob = await reader.result.toString();
  //         this.imgpath = await reader.result.toString().replace("data:image/gif;base64,", "");
  //       }
  //       else {
  //         await this.images.push(
  //             {
  //               src: reader.result.toString(),
  //               preview: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
  //             }
  //           )

  //       }
  //     }
  //   };
  // }
          
  // };
  //       input.change(trigger);
  //       input.attr('accept', 'image/gif');
  //       input.trigger("click"); 
  $("#gif-input").click();
  }
  async TakePhoto(which) {
    // let loading = await this.loadingCtrl.create({
    //   message: this.translate.getParsedResult('en', 'Please wait...')
    // });
    // let takephoto = await this.modalCtrl.create({
    //   component: TakePhotoPage
    // });
    // takephoto.present();
    // takephoto.onDidDismiss().then(async (data:any) => {
    //   data = data.data;
    //   if (data.src) {
    //     if (which == 'main') {
    //       this.imgblob = await 'data:image/jpeg;base64,' + data.src;
    //       this.imgpath = data.src;
    //       loading.dismiss();
    //     }
    //     else {
    //         await this.images.push(
    //           {
    //             src: 'data:image/jpeg;base64,' + data.src,
    //             preview: 'data:image/jpeg;base64,' + data.src
    //           }
    //         );
    //         loading.dismiss();
    //     }
    //   }
    //   else {
    //     loading.dismiss();
    //   }
    // })
    
    const image = await Plugins.Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera
    });
    console.log("images:", image);
    // image.webPath will contain a path that can be set as an image src. 
    // You can access the original file using image.path, which can be 
    // passed to the Filesystem API to read the raw data of the image, 
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    var imageUrl = 'data:image/jpeg;base64,' + image.base64String;
    await this.images.push(
              {
                src: imageUrl,
                preview: imageUrl
              }
            );
  
  }
  takePic() {
    const options: CameraOptions = {
      quality: 100,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.CAMERA,
    }
    
    this.camera.getPicture(options).then((imageData) => {
     // imageData is either a base64 encoded string or a file URI
     // If it's base64 (DATA_URL):
     this.nativepath = imageData;
           this.imgpath = imageData;
           this.imgblob = 'data:image/jpeg;base64,' + imageData;
           setTimeout( ()=> {
           }, 500)
    }, (err) => {
     // Handle error
    });  
  }
async uploadfn(which){

if (!this.platform.is('ios') && this.isApp) {
  let loading = await this.loadingCtrl.create({
    message: this.translate.getParsedResult('en', 'Please wait...')
  });
        loading.present();
const options: CameraOptions = {
  quality: 100,
  destinationType: this.camera.DestinationType.DATA_URL,
  encodingType: this.camera.EncodingType.JPEG,
  mediaType: this.camera.MediaType.PICTURE,
  sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
}

this.camera.getPicture(options).then(async (imageData) => {
 // imageData is either a base64 encoded string or a file URI
 // If it's base64 (DATA_URL):
 loading.dismiss();
 if (which == 'main') {
  this.nativepath = imageData;
          this.imgpath = imageData;
          this.imgblob = await 'data:image/jpeg;base64,' + imageData;

 }
 else {
    await this.images.push({
      src: 'data:image/jpeg;base64,' + imageData,
      preview: 'data:image/jpeg;base64,' + imageData
    });
    loading.dismiss();   
    
 }
}, (err) => {
  loading.dismiss();
});  

}
else {
  // var input:any = $(document.createElement("input"));
  //       input.attr("type", "file");
  //       input.attr("multiple", false);
  //       // add onchange handler if you wish to get the file :)
  //       let trigger = (event)=> {
  //         console.log("CLick triggred");
  //         let reader = new FileReader();
  //       if(event.target.files && event.target.files.length > 0) {
  //         let file = event.target.files[0];
  //         reader.readAsDataURL(file);
  //         reader.onload = async () => {
  //           if (file.type == "image/png" || file.type == "image/x-png" || file.type == "image/jpeg") {
  //             console.log(file.type);
  //             if (this.which == 'main') {
  //               this.imgblob = await reader.result.toString();
  //               this.imgpath = await reader.result.toString().replace(/^data:image\/[a-z]+;base64,/, "");
  //             }
  //             else {
  //               await this.images.push(
  //                   {
  //                     src: reader.result.toString(),
  //                     preview: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
  //                   }
  //                 )

  //             }
  //           }
  //         };
  //       }
  // };
  //       input.on('change', trigger);
  //       input.attr('accept', 'image/x-png,image/png,image/jpeg');
  //       input.trigger("click"); 
$("#file-input").click();
}
}  
fileChanged(event) {
            console.log("CLick triggred");
          let reader = new FileReader();
        if(event.target.files && event.target.files.length > 0) {
          let file = event.target.files[0];
          reader.readAsDataURL(file);
          reader.onload = async () => {
            if (event.target.files[0].size > 5000000) {
              (await this.alertCtrl.create({
                  header: '',
                  message: this.translate.getParsedResult('en', 'Image size is too big'),
                  buttons: [{
                      text: this.translate.getParsedResult('en', 'Ok'),
                      role: 'cancel'
                  }]
              })).present();
              return;
            }
            if (file.type == "image/png" || file.type == "image/x-png" || file.type == "image/jpeg" || file.type == 'image/gif') {
              console.log(file.type);
              if (this.which == 'main') {
                this.imgblob = await reader.result.toString();
                this.imgpath = await reader.result.toString().replace(/^data:image\/[a-z]+;base64,/, "");
              }
              else {
                await this.images.push(
                    {
                      src: reader.result.toString(),
                      preview: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
                    }
                  )

              }
            }
        }
      }
}

gifFileChanged(event) {
  var input:any = $(document.createElement("input"));
  console.log("CLick triggred");
  let reader = new FileReader();
  if(event.target.files && event.target.files.length > 0) {
    let file = event.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = async () => {
      if (event.target.files[0].size > 5000000) {
        (await this.alertCtrl.create({
            header: '',
            message: this.translate.getParsedResult('en', 'Image size is too big'),
            buttons: [{
                text: this.translate.getParsedResult('en', 'Ok'),
                role: 'cancel'
            }]
        })).present();
      }
      else
      if (file.type == "image/gif") {
        console.log(file.type);
        if (this.which == 'main') {
          // let x = (data) => this.imgblob = data;
          this.imgblob = await reader.result.toString();
          this.imgpath = await reader.result.toString().replace("data:image/gif;base64,", "");
        }
        else {
          await this.images.push(
              {
                src: reader.result.toString(),
                preview: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
              }
            )

        }
      }
    };
  }      
}

  iospic(which) {
let options = {
  maximumImagesCount: 1,
  quality: 100,
  outputType: 1
}
  this.imagePicker.getPictures(options).then((results) => {
  for (var i = 0; i < results.length; i++) {
    if (which == 'main') {
      this.imgpath = results[i];
      this.imgblob = 'data:image/jpeg;base64,' + results[i];
      this.imgpath = results[i];
    }
    else {
      this.images.push(
        {
          src: 'data:image/jpeg;base64,' + results[i],
          preview: 'data:image/jpeg;base64,' + results[i]
        }
      )
    }
  
    
            
  }
}, async (err) => {
  (await this.alertCtrl.create({
    message: this.translate.getParsedResult("en", "GIFS not supported")
  })).present();
 });
  }

  remove(index) {
    this.images.splice(index, 1);
  }
  dismiss() {
    this.modalCtrl.dismiss();
  }

  public submit() {
    this.imagesName = [];
    this.parseDescription().then((des)=> {
      this.parseCategorey().then(()=> {
        this.parseCity().then(()=> {
          this.parseLink().then(async (website:string)=> {
            this.parseTimer().then(async ()=> {
              console.log("The website will be:",website);
              var add = async ()=> {
                // Add 
                let loading = await this.loadingCtrl.create({
                  message: this.translate.getParsedResult('en', 'Please wait...')
                });
                loading.present();
                var userData;
                try {
                  userData = await this.oneSignal.getIds();
                }
                catch {
                  userData = '1436';
                }
                this.helper.posturl('addpaid', 'city=' + this.city + '&imgblob=' + this.imgpath + '&images=' + JSON.stringify(this.imagesName) + '&title=' + this.title + '&deal_price=' + this.price + '&price_color=' + this.pricecolor + '&time_diy=' + this.timer + '&description= ' + '&category=' + this.category + '&location=' + this.location + '&contact_phone=' + this.number + '&deals_yt_url=' + this.yvideo + '&wht_number=' + this.wnumber + '&contact_person=' + this.myname + '&user_id=' + this.loginid + '&lat=' + this.lat + '&lng=' + this.lng + 
                '&logintype=' + this.logintype + '&price_before=' + this.pricebefore + '&video_blob=' + this.videoblob + '&website=' + encodeURIComponent(website)+ '&website_before=' + this.websitebefore + '&isyoutube=' + this.isyoutube + "&animation=" + this.animation + '&user_id=' + userData.userId
                + '&tags=' + this.tags).
                subscribe(async (data:any) => {
                  loading.dismiss();
                    if (data) {
                  console.log(data);
                  this.oneSignal.sendTag("pId", data);
                  this.ref();
                    } else {
                      const alert = await this.alertCtrl.create({
                    header: this.translate.getParsedResult('en', 'Sorry'),
                    message: this.translate.getParsedResult('en', 'There is a problem in the server please try again later'),
                    buttons: [
                          {
                        text: this.translate.getParsedResult('en', 'Ok'),
                        handler: data => {
                          this.ref();
                        }
                      }
                    ]
                  });
                  alert.present();
                      
                    }
              
                  }, async (error) => {
                    console.log("ADDING NOT OK", error);
              
                        loading.dismiss();
                    const alert = await this.alertCtrl.create({
                              header: this.translate.getParsedResult('en', 'Sorry'),
                              message: this.translate.getParsedResult('en', 'Your Internet is slow, Please try again later'),
                              buttons: [{
                                  text: this.translate.getParsedResult('en', 'Ok'),
                                  role: 'cancel',
                              }]
                          });
                          alert.present();
                  });
              }
              if (this.images && this.images.length > 0 )
              this.parseImages(0, add)
              else {
                (await this.alertCtrl.create({
                  header: '',
                  message: this.translate.getParsedResult('en', 'Should select an image'),
                  buttons: [{
                      text: this.translate.getParsedResult('en', 'Ok'),
                      role: 'cancel'
                  }]
              })).present();
                // add();
              }
            })
          })

        })
      })
    })  
  }

  private async ref() {
    this.dismiss();
    // this.navCtrl.setRoot('GuestPage', {add: 1});  
    let alert = await this.alertCtrl.create({
      message:this.translate.getParsedResult('en', 'Product sent, and it is under review'),
      // duration: 8000,

      buttons: [
        {
          text: this.translate.getParsedResult('en', "Ok"),
          role: "cancle"
        }
      ]
    });
  
    alert.present();
  }

  private async parseImages(where, callback) {
    console.log('WE will loop in,', this.images);
      let loading = await this.loadingCtrl.create({
        message: this.translate.getParsedResult('en', 'Uploading images') + '...'
      });
      loading.present();
      await this.helper.posturl('upload', 'data=' + this.images[where]['src']).subscribe(async (res) => {
          console.log('Images 1:', this.images[where]);
          loading.dismiss();
          if (res) {
            // await this.pushToimages(res, ()=> {
              await this.imagesName.push(res);
              console.log('Images:', this.imagesName);
              if (where == this.images.length - 1) {
                loading.dismiss();
                setTimeout(()=> {
                  // this.isFree ? this.uploadMain() : this.paypal()
                  // this.uploadMain()
                  callback()
                }, 1000)
              } else {
                this.parseImages(where+1, callback)
              }
            // })

          }
          else {
            const alert = await this.alertCtrl.create({
              header: this.translate.getParsedResult('en', 'Sorry'),
              message: this.translate.getParsedResult('en', 'Error accord while uploading the images please try again'),
              buttons: [{
                  text: this.translate.getParsedResult('en', 'Ok'),
                  role: 'cancel',
                  handler: () => {
                      alert.dismiss();
                  }
              }]
          });
          alert.present();
             return;
          }
       }, async (error) => {
        loading.dismiss();
         console.log(error);
        //  this.loading.dismiss();
        const alert = await this.alertCtrl.create({
          header: this.translate.getParsedResult('en', 'Sorry'),
          message: this.translate.getParsedResult('en', 'Error accord while uploading the images please try again'),
          buttons: [{
              text: this.translate.getParsedResult('en', 'Ok'),
              role: 'cancel',
              handler: () => {
                  alert.dismiss();
              }
          }]
      });
      alert.present();
         return;
       })
}

public parseDescription():any {
  return new Promise(async (resolve, reject)=> {

  if (!this.title) {
    this.content.scrollToTop();
    (await this.alertCtrl.create({
      header: '',
      message: this.translate.getParsedResult('en', 'Title is needed'),
      buttons: [{
          text: this.translate.getParsedResult('en', 'Ok'),
          role: 'cancel'
      }]
  })).present();
  }
  else {
    resolve();
  }
  })
}

public parseTimer():any {
  return new Promise(async (resolve, reject)=> {

  if (this.timer < 1 || this.timer > 720) {
    (await this.alertCtrl.create({
      header: '',
      message: this.translate.getParsedResult('en', 'Product time is needed'),
      buttons: [{
          text: this.translate.getParsedResult('en', 'Ok'),
          role: 'cancel'
      }]
  })).present();
  }
  else {
    resolve();
  }
  })
}

private parseCategorey() {
  return new Promise(async (resolve)=> {
    if (this.category == '' || !this.category) {
      (await this.alertCtrl.create({
        header: '',
        message: this.translate.getParsedResult('en', 'Should select a category'),
        buttons: [{
            text: this.translate.getParsedResult('en', 'Ok'),
            role: 'cancel'
        }]
    })).present();
    }
    else resolve();
  })
}
private getLink(link) {
  var replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  var replacedText = link.match(replacePattern1);
  if (replacedText) {
    // console.log("Link:", replacedText);
    return replacedText ? replacedText[0] : null
  }
  else {
    var replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = link.match(replacePattern2);
    // console.log("Link:", replacedText);
    return replacedText ? "https://"+ replacedText[0] : null
  }
}
private parseLink() {
  // this.getLink(this.website);
  return new Promise(async (resolve)=> {
    if (!this.website) {
      const alert = await this.alertCtrl.create({
        header: this.translate.getParsedResult('en', 'Please Enter a link'),
        buttons: [{
            text: this.translate.getParsedResult('en', 'Ok'),
            role: 'cancel',
            handler: () => {
                alert.dismiss();
            }
        }]
    });
    alert.present();
    }
    else {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    if (!!pattern.test(this.website)) {
      let url = this.website;
      if (!/^https?:\/\//i.test(url)) {
        url = 'http://' + url;
      }
      resolve(url);
      }
      else {
        const alert = await this.alertCtrl.create({
          header: this.translate.getParsedResult('en', 'Link is not vaild'),
          message: this.translate.getParsedResult('en', 'Link should start with www or http://'),
          buttons: [{
              text: this.translate.getParsedResult('en', 'Ok'),
              role: 'cancel',
              handler: () => {
                  alert.dismiss();
              }
          }]
        });
        alert.present();
      }
    }
  })
}

private parseCity() {
  return new Promise(async (resolve)=> {
    if (!this.city) {
      const alert = await this.alertCtrl.create({
        header: '',
        message: this.translate.getParsedResult('en', 'Please Choose a city'),
        buttons: [{
            text: this.translate.getParsedResult('en', 'Ok'),
            role: 'cancel',
            handler: () => {
                alert.dismiss();
            }
        }]
    });
    alert.present();
    }
    else {
      resolve();
    }
  })
}


websiteChanged(event) {
  console.log(event.key);
  if (/[\u0600-\u06FF]/.test(event.key))
  event.preventDefault();
  return null;
}

  async openTerms() {
    let mo = await this.modalCtrl.create({
      component: TermsPage
    });
    mo.present();
  }
}
