import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Network } from '@ionic-native/network/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { Globalization } from '@ionic-native/globalization/ngx';

import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CitysPageModule } from './citys/citys.module';
import { AddLastPageModule } from './add-last/add-last.module';
import { TakePhotoPageModule } from './take-photo/take-photo.module';
import { AnimatesPageModule } from './animates/animates.module';
import { TermsPageModule } from './terms/terms.module';
import { Device } from '@ionic-native/device/ngx';

export function Ts(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json' );
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (Ts),
          deps: [HttpClient]
        }
      }),
      HttpClientModule,
      CitysPageModule,
      AddLastPageModule,
      TakePhotoPageModule,
      AnimatesPageModule,
      TermsPageModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Network,
    ImagePicker,
    Camera,
    OneSignal,
    CameraPreview,
    Globalization,
    AndroidFullScreen,
    Device,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
