import { Component } from '@angular/core';
import { Platform, ToastController, AlertController, ModalController } from '@ionic/angular';
import { Network } from '@ionic-native/network/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Globalization } from '@ionic-native/globalization/ngx';
import { Storage } from '@ionic/storage';
import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';

import { HelperService } from './helper.service';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import {
  Plugins,
  StatusBarStyle,
} from '@capacitor/core';
const { StatusBar, SplashScreen } = Plugins;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  nete: any = true;
  private toastOpened: boolean = false;
  constructor(
    private platform: Platform,
    private network: Network,
    public translate: TranslateService,
    private globalization: Globalization, private storage: Storage, public toastCtrl : ToastController,
    private androidFullScreen: AndroidFullScreen, public alertCtrl: AlertController,
    public helper: HelperService, private oneSignal: OneSignal, private modalCtrl: ModalController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      // this.statusBar.styleDefault();
      StatusBar.setStyle({
        style: StatusBarStyle.Light
      });
      StatusBar.setBackgroundColor({
        color: '#ffffff'
      });
      SplashScreen.hide();
      this.oneSignal.startInit('fa2622c3-74c5-4a11-a6bb-724dcd25e2a6', '670585438189');
        this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
        this.oneSignal.handleNotificationReceived().subscribe(data => console.log(data));
        this.oneSignal.handleNotificationOpened().subscribe(data => console.log(data));
        this.oneSignal.endInit();
        let noInternetToast = await this.toastCtrl.create({
          // title: this.translate.getParsedResult('en', "GPS is not activated"),
          message: this.translate.getParsedResult('en', 'There is no internet connection, please check your connection'),
          position: 'bottom',
          cssClass: "toastc",
          buttons: [
            {
              text: this.translate.getParsedResult('en', 'Ok'),
              role: 'cancel',
              handler: () => {
              }
            }
          ]
        });
        this.network.onDisconnect().subscribe(async ()=> {
          try {
            noInternetToast.dismiss();
        } catch(e) {};
        
          noInternetToast = await this.toastCtrl.create({
            // title: this.translate.getParsedResult('en', "GPS is not activated"),
            message: this.translate.getParsedResult('en', 'There is no internet connection, please check your connection'),
            position: 'bottom',
            cssClass: "toastc",
            buttons: [
              {
                text: this.translate.getParsedResult('en', 'Ok'),
                role: 'cancel',
                handler: () => {
                }
              }
            ]
          });
          if (!this.toastOpened) {
            noInternetToast.present();
            this.toastOpened = true;
          }
        })
        this.network.onConnect().subscribe(()=> {
          noInternetToast.dismiss()
          this.toastOpened = false;
        });

        this.storage.get('lan').then( (lan)=> {
           console.log('GOT LAN:', lan);
          if (lan == null || lan == "") {
            if (this.platform.is('cordova')) 
            this.globalization.getPreferredLanguage()
            .then(res => {
              let nl = res.value.slice(0, 2);
              if (nl == "ar") {
  
              }
              console.log(res);
              this.translate.setDefaultLang(nl);
              this.storage.set('lan', nl);
            })
            .catch(e => console.log(e));
            else {
              this.translate.setDefaultLang('ar');
              this.storage.set('lan', 'ar');
            }
          }
          else {
            this.translate.setDefaultLang(lan);
          }
        }, error => {
          console.log("ERROR In Storage:", error);
        })
      var offline = 0;
      if (this.network.type == 'none' ||  this.network.type == 'unknown') {
      offline = 1;
    } 
    });
  }
}
