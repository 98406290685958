
import { Device } from '@ionic-native/device/ngx';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';


@Injectable({
  providedIn: 'root'
})
export class IosVersion {

    constructor(public device: Device, public platform:Platform) {
    }

    get():Number {
        if (this.platform.is('cordova'))  {
            return parseFloat(this.device.version);
        }
        else {
            var match = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/),
                version = [
                    parseInt(match[1], 10),
                    parseInt(match[2], 10),
                    parseInt(match[3] || '0', 10)
                ];
        
            return parseFloat(version.join('.'));
        }
    }
}