import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  {
    path: 'fav',
    loadChildren: () => import('./fav/fav.module').then( m => m.FavPageModule)
  },
  {
    path: 'add-last',
    loadChildren: () => import('./add-last/add-last.module').then( m => m.AddLastPageModule)
  },
  {
    path: 'citys',
    loadChildren: () => import('./citys/citys.module').then( m => m.CitysPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'take-photo',
    loadChildren: () => import('./take-photo/take-photo.module').then( m => m.TakePhotoPageModule)
  },
  {
    path: 'animates',
    loadChildren: () => import('./animates/animates.module').then( m => m.AnimatesPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
