import { Component, OnInit } from '@angular/core';
// import { CameraPreview, CameraPreviewPictureOptions, CameraPreviewOptions, CameraPreviewDimensions } from '@ionic-native/camera-preview/ngx';
import { NavParams, NavController, Platform, ModalController } from '@ionic/angular';
import * as $ from 'jquery';
import {CameraPreview} from 'capacitor-camera-preview';
// import { Plugins } from "@capacitor/core"
// const { CameraPreview } = Plugins

@Component({
  selector: 'app-take-photo',
  templateUrl: './take-photo.page.html',
  styleUrls: ['./take-photo.page.scss'],
})
export class TakePhotoPage implements OnInit {
  public model: boolean = false;
  public image: any;
  public imagesData: any;
  constructor(
    // private cameraPreview: CameraPreview,
    public navCtrl: NavController, public navParams: NavParams,
    public platform: Platform,
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {
    let tapEnabled: any = false;
      let dragEnabled: any = false;
      let toBack: any = true;
      let alpha = 1;
      let rect: any = {
        x: 0,
        y: 0,
        width: this.platform.width(),
        height: this.platform.height()
      };
 
      // CameraPreview.start( {
      //   x: 0,
      //   y: 0,
      //   width: window.screen.width,
      //   height: window.screen.height,
      //   camera: 'rear',
      //   tapPhoto: true,
      //   previewDrag: false,
      //   toBack: true,
      //   alpha: 1
      // }
      
      // );
      setTimeout(()=> {
        CameraPreview.start({
          parent: "haha",
          className: 'haha'
        });
      }, 2000);
  }

  
  ionViewWillEnter() {
    $('ion-app').css('visibility', 'hidden');
    $('app-take-photo').css('visibility', 'visible');
  }
  ionViewWillLeave() {
    $('ion-app').css('visibility', 'visible');
    CameraPreview.stop();
  }

  async takePicture() {
    const pictureOpts = {
      width: 800,
      height: 600,
      quality: 50
    }
    // CameraPreview.takePicture(pictureOpts).then((imageData) => {
    //   this.image = imageData[0]
    //   this.imagesData = imageData[0]
    //   // console.log(imageData);
    // }, (err) => {
    //   console.log(err);
    // });
    const result = await CameraPreview.capture();
    const base64PictureData = result.value;
    this.image = this.imagesData = base64PictureData;
    this.model = true;
  }

  public toggleCamera() {
    CameraPreview

  }

}
