import { NgModule } from '@angular/core';

import { ColorPickerComponent } from './color-picker.component';
import { CommonModule } from '@angular/common';  
import { IonicModule } from '@ionic/angular';

@NgModule({
	declarations: [ColorPickerComponent],
	imports: [CommonModule, IonicModule],
	exports: [ColorPickerComponent],
})
export class ColorPickerComponentModule {}