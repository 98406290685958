import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AnimatesPage } from './animates.page';

const routes: Routes = [
  {
    path: '',
    component: AnimatesPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AnimatesPageRoutingModule {}
