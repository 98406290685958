import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.page.html',
  styleUrls: ['./terms.page.scss'],
})
export class TermsPage implements OnInit {

  constructor(
    private navCtrl: NavController, public translate: TranslateService, private model: ModalController
  ) { }

  ngOnInit() {
  }
  back() {
    this.model.dismiss();
  }
}
