import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AnimatesPageRoutingModule } from './animates-routing.module';

import { AnimatesPage } from './animates.page';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerComponentModule } from '../color-picker/color-picker.component.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AnimatesPageRoutingModule,
    TranslateModule,
    ColorPickerComponentModule
  ],
  declarations: [AnimatesPage]
})
export class AnimatesPageModule {}
