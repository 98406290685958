import { Component, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { HelperService } from '../helper.service';
import { Storage } from '@ionic/storage';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-citys',
  templateUrl: './citys.page.html',
  styleUrls: ['./citys.page.scss'],
})
export class CitysPage implements OnInit {
	citys: any;
  bu: any;
  public arl:boolean = false;
  constructor(
    public navCtrl: NavController,
    public helper: HelperService,
    public modalController: ModalController,
    private storage: Storage,
    private translate: TranslateModule
  ) { }

  ngOnInit() {
    this.helper.geturl('citys').subscribe( data => {
  		console.log('citys', data);
      this.citys = data;
  		// if (this.navParams.get('mycity')) {
  		// this.citys.push(this.navParams.get('mycity'));
  		// }
  		this.bu = this.citys;
    })
    this.storage.get('lan').then((lan)=> {
      this.arl = lan == 'ar';
    })
  }

  to(city) {
  	this.modalController.dismiss({
  		city: city
  	});
  }
    showResults(ev) {
     this.citys = [];
    for (let i = 0; i<this.bu.length; i++) {
      if (this.bu[i]['city'].indexOf(ev) > -1) {
        this.citys.push(this.bu[i]);
      }
    }
  }

}
